import React, { useEffect, useState, lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

// Import components
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import LoadingComponent from "./components/loading/LoadingComponent";
import AOS from "aos";
import "aos/dist/aos.css";

// Lazy-loaded pages
const Home = lazy(() => import("./pages/home/Home"));
const Login = lazy(() => import("./pages/auth/Login"));
const Profile = lazy(() => import("./pages/profile/Profile"));
const Settings = lazy(() => import("./pages/settings/Settings"));
const Withdraw = lazy(() => import("./pages/withdraw/Withdraw"));
const Users = lazy(() => import("./pages/users/Users"));
const Proposals = lazy(() => import("./pages/proposals/Proposals"));
const Accepted = lazy(() => import("./pages/proposals/Accepted"));
const Rejected = lazy(() => import("./pages/proposals/Rejected"));
const BusinessProfile = lazy(() => import("./pages/profile/BusinessProfile"));
const Hire = lazy(() => import("./pages/hire/Hire"));
const AddFunds = lazy(() => import("./pages/funds/AddFunds"));
const ForgetPassword = lazy(() => import("./pages/auth/ForgetPassword"));
const SinglePost = lazy(() => import("./pages/posts/SinglePost"));
const ResetPassword = lazy(() => import("./pages/auth/ResetPassword"));
const AllHires = lazy(() => import("./pages/hire/AllHires"));
const FollowerProfile = lazy(() => import("./pages/profile/FollowerProfile"));
const Test = lazy(() => import("./Test"));
const Analytics = lazy(() => import("./pages/analytics/Analytics"));
const ProposalAnalytics = lazy(() =>
  import("./pages/analytics/ProposalAnalytics")
);
const FeedPost = lazy(() => import("./pages/posts/FeedPost"));
const SinglePoll = lazy(() => import("./pages/posts/SinglePoll"));
const ChatContainer = lazy(() => import("./pages/messages/ChatContainer"));
const BusinessChat = lazy(() =>
  import("./pages/messages/businessChatContainer")
);
const OtherProfile = lazy(() => import("./pages/profile/OtherProfile"));
const Followers = lazy(() => import("./pages/users/Followers"));
const OtherBusinessProfile = lazy(() =>
  import("./pages/profile/OtherBusiness")
);
const OtherFollowerProfile = lazy(() =>
  import("./pages/profile/OtherFollower")
);
const TeamSection = lazy(() => import("./pages/OurTeam/TeamSection"));
const ContactUs = lazy(() => import("./pages/contact/Contact"));
const NewPostFeeds = lazy(() => import("./pages/posts/NewPostFeeds"));
const AllMessages = lazy(() => import("./pages/messages/AllMessages"));
const WelcomePage = lazy(() => import("./pages/auth/WelcomePage"));
const EmailVerify = lazy(() => import("./pages/auth/EmailVerify"));
const NewAuthFeeds = lazy(() => import("./pages/posts/NewAuthFeeds"));
const TermsOfService = lazy(() => import("./pages/policies/Terms"));
const Privacy = lazy(() => import("./pages/policies/Privacy"));
const Contracts = lazy(() => import("./pages/policies/Contracts"));
const BusinessAllMessages = lazy(() =>
  import("./pages/messages/BusinessAllMessages")
);
const Reviews = lazy(() => import("./pages/reviews/Reviews"));
const RegInfluencer = lazy(() => import("./pages/auth/RegInfluencer"));
const SingleReviewPage = lazy(() => import("./pages/reviews/SingleReview"));
const SingleFeed = lazy(() =>
  import("./pages/posts/post-comp/main-posts/SingleFeed")
);
const FollowerLogin = lazy(() => import("./pages/auth/FollowerLogin"));
const BusinessLogin = lazy(() => import("./pages/auth/BusinessLogin"));
const SponsoredFeeds = lazy(() =>
  import("./pages/posts/post-comp/main-posts/SponsoredFeeds")
);
const NewSidePostFeeds = lazy(() => import("./pages/posts/NewSidePostFeed"));
const Transactions = lazy(() => import("./pages/withdraw/Transactions"));
const FollowerReg = lazy(() => import("./pages/auth/FollowerReg"));
const InfluencerReg = lazy(() => import("./pages/auth/InfluencerReg"));
const BusinessReg = lazy(() => import("./pages/auth/BusinessReg"));
const SpecificHire = lazy(() => import("./pages/hire/SpecificHire"));
const WithdrawalHistory = lazy(() =>
  import("./pages/withdraw/WithdrawHistory")
);
const NotFoundPage = lazy(() => import("./pages/fof/Error"));

// Initialize Stripe with your publishable key
const stripePromise = loadStripe("pk_test_6pRNASCoBOKtIshFeQd4XMUh");

export const ScrollToTopButton = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleScroll = () => {
    const button = document.getElementById("scrollToTopBtn");
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      button.style.display = "block";
    } else {
      button.style.display = "none";
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <button
      id="scrollToTopBtn"
      onClick={scrollToTop}
      style={{ display: "none" }}
    >
      ↑
    </button>
  );
};

const AppContent = () => {
  const userRole = useSelector((state) => state.user?.role);
  const user = useSelector((state) => state.user?.user);
  const [loading, setLoading] = useState(true);

  //console.log(user);

  const location = useLocation();
  const navigate = useNavigate();

  const authPages = [
    "/login",
    "/follower-login",
    "/business-login",
    "/follower-reg",
    "/business-reg",
    "/influencer-reg",
    "/register",
    "/forgot-password",
    "/reset-password",
    "/contracts",
    "/privacy",
    "/terms",
    "/error-page",
    "/",
  ];

  const isApiRoute = (path) => /^\/api/.test(path);
  const isReviewRoute = (path) => /^\/review/.test(path);
  const isRegRoute = (path) => /^\/register/.test(path);

  // console.log("isApiRoute", !isRegRoute);

  useEffect(() => {
    AOS.init({ duration: 1000 });

    const checkVerification = async () => {
      const isVerified = user && localStorage.getItem("verified") === "true";

      setTimeout(() => {
        if (
          !isVerified &&
          !authPages.includes(location.pathname) &&
          !isApiRoute(location.pathname) &&
          !isReviewRoute(location.pathname) &&
          isRegRoute
        ) {
          navigate("/welcome");
        }
        setLoading(false); // Set loading to false after the delay
      }, 3000);
    };

    checkVerification();
  }, [location.pathname, navigate, user]);

  useEffect(() => {
    setTimeout(() => {
      if (
        !user &&
        !authPages.includes(location.pathname) &&
        !isApiRoute(location.pathname) &&
        !isReviewRoute(location.pathname) &&
        !isRegRoute(location.pathname)
      ) {
        navigate("/error-page");
      }
      setLoading(false);
    }, 3000);
  }, []);

  const hideFooter = location.pathname.startsWith("/chats");

  if (loading) {
    // Display a loading spinner or message until the verification is complete
    return (
      <div>
        <LoadingComponent />
      </div>
    );
  }

  return (
    <Suspense fallback={<LoadingComponent />}>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/follower-login" element={<FollowerLogin />} />
        <Route path="/business-login" element={<BusinessLogin />} />
        <Route path="/follower-reg" element={<FollowerReg />} />
        <Route path="/influencer-reg" element={<InfluencerReg />} />
        <Route path="/business-reg" element={<BusinessReg />} />

        <Route path="/register" element={<Login />} />
        <Route path="/register/influencer" element={<RegInfluencer />} />

        <Route path="/forgot-password" element={<ForgetPassword />} />

        <Route path="/profile/:id" element={<OtherProfile />} />
        <Route
          path="/profile"
          element={
            userRole && userRole === "influencer" ? (
              <Profile />
            ) : userRole === "business" ? (
              <BusinessProfile />
            ) : (
              <FollowerProfile />
            )
          }
        />
        <Route path="/settings" element={<Settings />} />
        <Route path="/withdraw" element={<Withdraw />} />
        <Route path="/withdraw-history" element={<WithdrawalHistory />} />
        <Route path="/transactions" element={<Transactions />} />
        <Route path="/influencers" element={<Users />} />
        {/* <Route path="/posts" element={<MainFeeds />} /> */}
        <Route path="/auth-posts" element={<NewAuthFeeds />} />
        <Route path="/followers" element={<Followers />} />
        <Route path="/proposals" element={<Proposals />} />
        <Route path="/accepted-proposals" element={<Accepted />} />
        <Route path="/rejected-proposals" element={<Rejected />} />
        <Route path="/create-post" element={<FeedPost />} />
        <Route path="/single-post/:id" element={<SinglePost />} />
        <Route path="/single-poll/:id" element={<SinglePoll />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/all-hires" element={<AllHires />} />
        <Route path="/analytics" element={<Analytics />} />
        <Route path="/proposal-analytics" element={<ProposalAnalytics />} />
        <Route path="/chats/:id" element={<ChatContainer />} />
        <Route path="/chats/business/:id" element={<BusinessChat />} />
        <Route path="/profile-business" element={<BusinessProfile />} />
        <Route path="/reviews/:id" element={<SingleReviewPage />} />

        <Route
          path="/profile-business/:id"
          element={<OtherBusinessProfile />}
        />
        <Route path="/hire" element={<Hire />} />
        <Route path="/hire/:id" element={<SpecificHire />} />

        <Route path="/add-funds" element={<AddFunds />} />
        <Route path="/feedpost" element={<FeedPost />} />
        <Route path="/test" element={<Test />} />
        <Route path="/profile-follower" element={<FollowerProfile />} />
        <Route path="/our-team" element={<TeamSection />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/contracts" element={<Contracts />} />

        <Route path="/about" element={<TeamSection />} />
        <Route path="/posts" element={<NewPostFeeds />} />
        <Route path="/sponsored-posts" element={<NewSidePostFeeds />} />

        <Route path="/posts/:id" element={<SingleFeed />} />
        <Route
          path="/all-messages"
          element={
            userRole == "business" ? <BusinessAllMessages /> : <AllMessages />
          }
        />
        <Route path="/welcome" element={<WelcomePage />} />
        <Route path="/reviews" element={<Reviews />} />

        <Route
          path={`/api/${userRole}/email/verify/:userId/:token`}
          element={<EmailVerify />}
        />

        <Route
          path={`/api/email/verify/:userId/:token`}
          element={<EmailVerify />}
        />

        <Route
          path="/profile-follower/:id"
          element={<OtherFollowerProfile />}
        />
        <Route path="/error-page" element={<NotFoundPage />} />
      </Routes>
      {!hideFooter && <Footer />}
    </Suspense>
  );
};

function App() {
  // const [stripeLoaded, setStripeLoaded] = useState(false);

  // useEffect(() => {
  //   const loadStripe = async () => {
  //     const stripeInstance = await loadStripe(
  //       "pk_test_6pRNASCoBOKtIshFeQd4XMUh"
  //     );
  //     setStripeLoaded(stripeInstance);
  //   };

  //   loadStripe();
  // }, []);

  return (
    <Router>
      <Elements stripe={stripePromise}>
        <AppContent />
      </Elements>

      <ToastContainer />

      {/* <ScrollToTopButton /> */}
    </Router>
  );
}

export default App;
