import React, { useEffect, useState } from "react";
import "./Header.css";
import { Avatar, Logo } from "../../assets/images/Images";
import {
  CloseIcon,
  MenuOpenIcon,
  MoreVertIcon,
} from "../../assets/images/icons";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../axiosInstance";
import { publicUrl } from "../../axios";
import { useLocation, useNavigate } from "react-router";
import { logout } from "../../redux/store/userSlice";
import LoadingComponent from "../loading/LoadingComponent";

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAuthOpen, setIsAuthOpen] = useState(false);
  const user = useSelector((state) => state.user.user);
  const [loading, setLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false); // New state for isVerified
  const dispatch = useDispatch();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const toggleAuth = () => {
    setIsAuthOpen(!isAuthOpen);
  };

  const [userDetails, setUserDetails] = useState({});
  const influencerEndpoint = "/influencer/get-profile";
  const businessEndpoint = "/business/get-profile";
  const followerEndpoint = "/follower/get-profile";

  const userRole = useSelector((state) => state.user?.role);
  const location = useLocation();
  const navigate = useNavigate();

  const profileDetails = async () => {
    try {
      setLoading(true);
      const res = await axiosInstance.get(
        userRole === "influencer"
          ? influencerEndpoint
          : userRole === "business"
          ? businessEndpoint
          : followerEndpoint
      );
      setUserDetails(res.data?.user_profile);
      setLoading(false);
    } catch (error) {
      //console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    profileDetails();
  }, []);

  const handleLogout = () => {
    setLoading(true);
    dispatch(logout()); // Call the logout action

    localStorage.setItem("verified", "false");
    // navigate("/login");
    window.location.href = "/login";
  };

  useEffect(() => {
    const verified = localStorage.getItem("verified") === "true";
    setIsVerified(verified);
  }, [location.pathname, navigate]);

  //console.log("verify", isVerified);

  return (
    <div>
      <header>
        <nav className="pc__nav">
          <div className="logo">
            <a href="/">
              <img src={Logo} alt="Top Notch Influencer" loading="lazy" />
            </a>
          </div>
          {!user ? (
            <ul className="nav-links">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/#about">About</a>
              </li>
              <li>
                <a href="/#client">Clients</a>
              </li>
              <li>
                <a href="/#features">Features</a>
              </li>
              <li>
                <a href="/#faq">FAQ's</a>
              </li>
              <li>
                <a href="/#contact">Contact</a>
              </li>
            </ul>
          ) : (
            <ul className="nav-links">
              <li>
                <a href="/posts">Posts</a>
              </li>
              <li>
                <a href="/analytics">Analytics</a>
              </li>
              {userRole !== "follower" && (
                <>
                  <li>
                    <a href="/proposals">Proposals</a>
                  </li>
                  <li>
                    <a href="/all-messages">Messages</a>
                  </li>
                </>
              )}
            </ul>
          )}
          <div className="auth-buttons">
            {!user ? (
              <>
                <button
                  className="sign-up"
                  onClick={() => (window.location.href = "/login")}
                >
                  <a href="/login"> Sign up </a>
                </button>
                <button
                  className="get-started"
                  onClick={() => (window.location.href = "/login")}
                >
                  <a href="/login"> Get Started </a>
                </button>
              </>
            ) : !isVerified ? (
              <div
                style={{
                  color: "white",
                  textTrandform: "capitalize",
                  fontWeight: "bold",
                  textDecoration: "none",
                }}
                onClick={handleLogout}
              >
                Logout
              </div>
            ) : (
              <a href="/profile">
                <img
                  loading="lazy"
                  src={
                    userDetails && userDetails?.image
                      ? publicUrl + userDetails?.image
                      : Avatar
                  }
                  alt="user image"
                  style={{ width: 40, height: 40, borderRadius: 20 }}
                />
              </a>
            )}
          </div>
        </nav>
      </header>

      <div className="mobile">
        <nav className="mobile__nav">
          <div className="hamburger-menu" onClick={toggleMenu}>
            {isMenuOpen ? <CloseIcon /> : <MenuOpenIcon />}
          </div>
          <div className="logo">
            <a href="/">
              <img src={Logo} alt="Top Notch Influencer" loading="lazy" />
            </a>
          </div>

          {!user ? (
            <div className="hamburger-menu" onClick={toggleAuth}>
              <MoreVertIcon />
            </div>
          ) : !isVerified ? (
            <div
              style={{
                color: "black",
                textTrandform: "capitalize",
                fontWeight: "bold",
                textDecoration: "none",
              }}
              onClick={handleLogout}
            >
              Logout
            </div>
          ) : (
            <>
              <a href="/profile">
                <img
                  loading="lazy"
                  src={
                    userDetails && userDetails?.image
                      ? publicUrl + userDetails?.image
                      : Avatar
                  }
                  alt="user image"
                  style={{ width: 40, height: 40, borderRadius: 20 }}
                />
              </a>
            </>
          )}
        </nav>

        {isMenuOpen && (
          <div className="mobile-menu" id="mobileMenu">
            {!user ? (
              <ul className="mobile-links">
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="/#about">About</a>
                </li>
                <li>
                  <a href="/#client">Clients</a>
                </li>
                <li>
                  <a href="/#features">Features</a>
                </li>
                <li>
                  <a href="/#faq">FAQ's</a>
                </li>
                <li>
                  <a href="/#contact">Contact</a>
                </li>
              </ul>
            ) : (
              <ul className="mobile-links">
                <li>
                  <a href="/posts">Posts</a>
                </li>
                <li>
                  <a href="/analytics">Analytics</a>
                </li>
                {userRole !== "follower" && (
                  <>
                    <li>
                      <a href="/proposals">Proposals</a>
                    </li>
                    <li>
                      <a href="/all-messages">Messages</a>
                    </li>
                  </>
                )}
              </ul>
            )}
          </div>
        )}

        {isAuthOpen && (
          <div className="mobile-auth-buttons">
            <button
              className="mobile-auth-buttons__sign-up"
              onClick={() => (window.location.href = "/login")}
            >
              Sign up
            </button>
            <button
              className="mobile-auth-buttons__get-started"
              onClick={() => (window.location.href = "/login")}
            >
              Get Started
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Header;
